<template>
  <div class="about pb-10">
    <HeroSection :image="'url(/assets/img/song-header.png)'"/>

    <div class="container mx-auto mt-6">
      <div class="about__screen">
        <img src="../assets/images/about/about_1.png" alt="website screen">
        <button class="about__label" @click="openAuthModal">
          <img src="../assets/icons/registration.svg" class="mr-3" alt="registration icon">
          {{ $t('about.register') }}
        </button>
      </div>

      <div class="about__screen">
        <img src="../assets/images/about/about_4.png" alt="website screen">
        <div class="about__screens-small">
          <img src="../assets/images/about/about_3.png" alt="website screen">
          <img src="../assets/images/about/about_2.png" alt="website screen">
        </div>
        <button class="about__label" @click="openAuthModal">
          <img src="../assets/icons/upload.svg" class="mr-3" alt="upload icon">
          {{ $t('about.upload') }}
        </button>
      </div>

      <div class="about__screen">
        <img src="../assets/images/about/about_6.png" alt="website screen">
        <button class="about__label" @click="openAuthModal">
          <img src="../assets/icons/comment.svg" class="mr-3" alt="comment icon">
          {{ $t('about.comment') }}
        </button>
      </div>

      <div class="about__screen">
        <img src="../assets/images/about/about_5.png" alt="website screen">
        <button class="about__label" @click="openAuthModal">
          <img src="../assets/icons/filter.svg" class="mr-3" alt="filter icon">
          {{ $t('about.filter') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeroSection from '@/components/HeroSection.vue';
import { useStore } from 'vuex'

const store = useStore()

const openAuthModal = () => {
  store.commit('toggleAuthModal')
}
</script>

<style lang="scss" scoped>
@mixin screen {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.about {
  &__screen {
    margin: 100px auto 0 auto;
    max-width: 880px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      @include screen;
    }

    &:first-child {
      margin-top: 20px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    @media (max-width: 480px) {
      margin-top: 70px;
    }
  }

  &__screens-small {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    img {
      @include screen;
      max-width: 400px;
    }

    @media (max-width: 1024px) {
      img {
        max-width: 360px;
      }
    }

    @media (max-width: 768px) {
      img {
        max-width: 280px;
        min-width: 200px;
      }
    }

    @media (max-width: 480px) {
      margin-top: 20px;
      flex-direction: column;

      img {
        width: auto;
        max-width: fit-content;
      }
    }
  }

  &__label {
    position: relative;
    max-width: 453px;
    width: fit-content;
    padding: 6px 20px;
    margin-top: 30px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Indie Flower', 'Neucha', cursive, sans-serif;
    font-size: 24px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s all ease-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

    &:after {
      content: "";
      position: absolute;
      width: 460px;
      height: 460px;
      left: 50%;
      top: 50%;
      margin-left: -230px;
      margin-top: -230px;
      background: linear-gradient(306deg, #6E5EC4 4.99%, #907FEC 81.79%);
      z-index: -1;
      transition: 0.3s all ease-out;
    }

    &:hover {
      box-shadow: -4px -4px 6px 0 rgba(0, 0, 0, 0.20);
      &:after {
        transform: rotate(180deg)
      }
    }

    img {
      width: 25px;
      height: auto;
    }

    @media (max-width: 480px) {
      margin-top: 20px;
      height: 40px;
      font-size: 18px;

      img {
        width: 20px;
        height: auto;
      }
    }
  }
}
</style>
